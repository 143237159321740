.mat-mdc-form-field {

    &.no-subscript-wrapper {
        .mat-mdc-form-field-subscript-wrapper {
            display: none;
        }
    }

    &.sm-pad-form-field {

        .mat-mdc-select {
            font-size: .875rem;
        }

        &.sm-field-pad-4 .mat-mdc-text-field-wrapper {
            padding: 0 .25rem;
        }

        .mat-mdc-text-field-wrapper {
            padding-bottom: 0;
            height: 40px;
            max-height: 40px;

            .mat-mdc-form-field-flex .mat-mdc-floating-label:not(.mdc-floating-label--float-above){
                top: 20px;   
            }
        }

        .mat-mdc-form-field-subscript-wrapper {
            display: none;
        }

        .mat-mdc-form-field-infix {
            padding: 0.5em 0 0.5em 0;
            min-height: 32px;
            font-size: .875rem;
        }

        .mat-mdc-form-field-icon-prefix, 
        .mat-mdc-form-field-icon-suffix {
            font-size: 0.75rem;
            color: var(--font-color-2);
        }
    }

    &.no-pad-form-field {
        .mat-mdc-form-field-wrapper {
            display: none;
        }
    }

    &.block-form-field {
        display: block;
        width: 100%;
    } 

}

.pad-form-field {
    padding-bottom: 1.34375em;
}

.mat-mdc-checkbox {
    &.full-width {
        width: 100%;

        /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
        .mat-checkbox-layout {
            width: 100%;
        }

        /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
        .mat-checkbox-label {
            flex: 1;
        }
    }
}

.form-row {
    display: flex;
    flex-direction: row;

    &.form-center {
        align-items: center;
    }
}

.mat-mdc-form-field .text-center {
    text-align: center;
}

.form-warning {
    font-size: .75rem;
    padding: .25rem;
    border-radius: .25rem;
    margin-top: .25rem;
    background-color: var(--bg-status-color-6);
    text-align: justify;
}


.mat-mdc-icon-button {

    &.bordered {
        border: var(--border-1);
    }

    &.mdc-icon-button-40 {
        height: 40px;
        width: 40px;
        padding: 8px;

        &.bordered {
            padding: 7px;
        }
    }

    &.mdc-icon-button-34 {
        height: 34px;
        width: 34px;
        padding: 8px;
        line-height: 18px;

        .mat-icon {
            font-size: 18px;
            line-height: 18px;
            width: 18px;
            height: 18px;
        }
    }
}


.mr-0 {
    margin: 0;
}