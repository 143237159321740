@import './assets/styles/variables.scss';

@import 'assets/styles/core';

@import './assets/styles/animations.scss';

::-ms-clear {
    display: none;
}

body {
    background-color: var(--bg-color-17);
}

//------------------------------

.mat-mdc-unelevated-button.mat-accent {
    --mdc-filled-button-label-text-color: #ffffff;
}

.mat-mdc-fab.mat-accent,
.mat-mdc-mini-fab.mat-accent {
    --mdc-fab-icon-color: #ffffff;
    --mat-mdc-fab-color: #ffffff;
}

.mat-mdc-checkbox.mat-accent {
    --mdc-checkbox-selected-checkmark-color: #ffffff;
}

//------------------------------

.nav .nav-item .nav-link .nav-link-icon,
.nav .nav-item .nav-link .collapse-arrow {
    /* size of navbar icons */
    font-size: 28px;
    width: 28px;
    height: 28px;
    min-width: 28px;
    min-height: 28px;
    line-height: 28px;
}

.mat-drawer-content {
    display: flex !important;
    flex: 1 1 0%;
    background-color: var(--bg-color-17);
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-mdc-table,
mat-table {
    width: 100%;

    td {
        max-width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        min-width: 40px;
    }

    &.with-sticky-header {
        border: 24px solid var(--bg-color-17);
        border-top: none;

        .mat-mdc-header-row {
            border-top: 24px solid var(--bg-color-17);
        }
    }

    .align-middle {
        vertical-align: middle;
    }

    .mat-mdc-cell {
        &.align-center {
            text-align: center;
        }
    }

    .mat-resizable-column {
        border-right: 1px dashed var(--font-color-5);
    }

    .mat-mdc-row,
    mat-row {
        min-height: 60px;
        height: 60px;
        cursor: pointer;
        user-select: none;
    }

    .cdk-column-select,
    .mat-column-select {
        overflow: visible;
        max-width: 60px;
        min-width: 60px;
        width: 60px;
    }

    .cdk-column-more,
    .mat-column-more {
        min-width: 60px;
        width: 60px;
        max-width: 60px;
    }

    .mat-column-enabled,
    .cdk-column-enabled {
        justify-content: center;
        text-align: center;
    }

    .mat-mdc-cell,
    .mat-mdc-header-cell {
        &:not(:first-child) {
            padding: 4px;
        }
    }

    th:hover .resize-holder {
        opacity: 1;
        transition: .3s ease-in-out;
    }

    .resize-holder {
        display: inline-block;
        position: absolute;
        top: 0;
        right: 0;
        width: 10px;
        height: 100%;
        cursor: ew-resize !important;
        opacity: 0;
    }
}


.mat-sort-header-arrow {
    opacity: 0;
}

.mat-mdc-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
    background: #e1f5fe !important;
}

.mat-mdc-menu-panel {
    min-height: initial !important;
}

.mat-mdc-menu-content {
    min-width: 200px;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.mat-mdc-menu-item {
    line-height: 49px;
    height: auto;
    font-size: .875rem;
}

.hidden {
    display: none !important;
}

.reset-transform {
    text-transform: initial !important;
}

.mat-icon-move {
    transform: scale(-1, 1);
}

/**
 * EDITOR MODULE
 */
.editor-dialog {
    max-width: 100vw !important;

    .mat-mdc-dialog-container {
        padding: 0;
    }

    /* Fixes 1px gap to the right bug in Blink engine */
    video,
    img,
    iframe,
    .player-image {
        transform: none;
        -webkit-transform: scaleX(1.004);
    }
}

.mat-mdc-tooltip.question-tooltip {
    font-size: .875rem;
}

.mat-mdc-header-cell,
.mat-mdc-cell {
    .-ie & {
        height: 56px;
    }
}

.spacer {
    flex: 1 1 0%;

    &.-fixed {
        width: 50px;
        flex-basis: 50px;
        flex-grow: 0;
        flex-shrink: 0;
    }

    &.-tiny {
        width: 15px;
        flex-basis: 15px;
        flex-grow: 0;
        flex-shrink: 0;
    }
}

//Forms
.mat-mdc-form-field {
    max-width: 400px;
}


/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
mat-checkbox {

    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    .mat-checkbox-inner-container {
        height: 15px;
        width: 15px;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    .mat-checkbox-frame {
        border-width: 1px;
    }
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
mat-selection-list {
    .mat-pseudo-checkbox {
        border: 1px solid;
    }

    .mat-pseudo-checkbox-checked::after {
        top: 1.4px;
        width: 12px !important;
        height: 6px !important;
        border-left: 1px solid currentColor !important;
    }

    .mat-pseudo-checkbox::after {
        border-left: 1px solid currentColor;
        border-bottom: 1px solid currentColor;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.*/
    .mat-chip-list-wrapper {
        .mat-icon {
            min-width: 18px;
            min-height: 18px;
            line-height: 18px;
        }
    }
}

.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.mat-tree-node {
    .-ie & {
        height: 48px;
    }
}

.resize {
    &-drag {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 4px;
        height: 100%;
        background-color: #e1e1e1;
        cursor: grab;
        user-select: none;

        &:hover {
            background-color: blue;
        }

        &.-dragging {
            cursor: grabbing;
            background-color: blue;
        }
    }
}

.spacer {
    flex-grow: 1;
}

.fill-selection {
    &__wrapper {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: block;
        border-color: #85bbf0;

        &:before {
            display: block;
            position: absolute;
            z-index: 1;
            text-align: center;
            border-width: 1px;
            border-color: inherit;
        }

        &::before {
            top: -10px;
            bottom: -10px;
            left: -10px;
            right: -10px;
            border-style: dashed;
            background: transparent;
            content: '';
            border-radius: 3px;
        }
    }
}

.scrollable {
    overflow-y: auto !important;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
        width: 0;
        display: none;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #ddd;
        background-clip: padding-box;
        border-radius: 2px;
        border: 4px solid rgba(0, 0, 0, 0);
    }
}

.md-tooltip {
    font-size: .875rem;
    white-space: pre-line;
}

.mat-mdc-tooltip-panel .mat-tooltip {
    max-width: 60vw;
}

.mat-light-blue-50-bg,
.light-blue-50-bg {
    background-color: #e1f5fe !important;
}

// BUTTONS
.mat-mdc-outlined-button {
    &.mat-primary {
        border-color: var(--primary-color);
    }
}

.mat-mdc-button-base:not(.mat-mdc-icon-button) {
    text-transform: uppercase;
    font-weight: 400;

    .mat-icon {
        color: inherit;
    }
}

.context-menu {
    background-color: white;
    min-width: 112px;
    max-width: 280px;
    overflow: auto;
    border-radius: .25rem;
    box-shadow: var(--shadow-1);
}

.mat-divider.mat-divider-vertical {
    height: 100%;
}

.read-only-bg {
    opacity: .6;
}
