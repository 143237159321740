
.grid {
    display: grid;
}

.gap-0 {
    grid-gap: 0;
    gap: 0
}

.gap-1 {
    grid-gap: .25rem;
    gap: .25rem
}

.gap-2 {
    grid-gap: .5rem;
    gap: .5rem;
}

.gap-3 {
    grid-gap: .75rem;
    gap: .75rem;
}

.gap-4 {
    grid-gap: 1rem;
    gap: 1rem;
}

.gap-5 {
    grid-gap: 1.25rem;
    gap: 1.25rem
}

.gap-6 {
    grid-gap: 1.5rem;
    gap: 1.5rem
}

.gap-8 {
    grid-gap: 2rem;
    gap: 2rem
}

.gap-10 {
    grid-gap: 2.5rem;
    gap: 2.5rem
}

.gap-12 {
    grid-gap: 3rem;
    gap: 3rem
}

.gap-16 {
    grid-gap: 4rem;
    gap: 4rem
}

.gap-20 {
    grid-gap: 5rem;
    gap: 5rem
}

.gap-24 {
    grid-gap: 6rem;
    gap: 6rem
}

.gap-32 {
    grid-gap: 8rem;
    gap: 8rem
}

.col-gap-0 {
    grid-column-gap: 0;
    column-gap: 0
}

.col-gap-1 {
    grid-column-gap: .25rem;
    column-gap: .25rem
}

.col-gap-2 {
    grid-column-gap: .5rem;
    column-gap: .5rem
}

.col-gap-3 {
    grid-column-gap: .75rem;
    column-gap: .75rem
}

.col-gap-4 {
    grid-column-gap: 1rem;
    column-gap: 1rem
}

.col-gap-5 {
    grid-column-gap: 1.25rem;
    column-gap: 1.25rem
}

.col-gap-6 {
    grid-column-gap: 1.5rem;
    column-gap: 1.5rem
}

.col-gap-8 {
    grid-column-gap: 2rem;
    column-gap: 2rem
}

.col-gap-10 {
    grid-column-gap: 2.5rem;
    column-gap: 2.5rem
}

.col-gap-12 {
    grid-column-gap: 3rem;
    column-gap: 3rem
}

.col-gap-16 {
    grid-column-gap: 4rem;
    column-gap: 4rem
}

.col-gap-20 {
    grid-column-gap: 5rem;
    column-gap: 5rem
}

.col-gap-24 {
    grid-column-gap: 6rem;
    -moz-column-gap: 6rem;
    column-gap: 6rem
}

.col-gap-32 {
    grid-column-gap: 8rem;
    -moz-column-gap: 8rem;
    column-gap: 8rem
}

.row-gap-0 {
    grid-row-gap: 0;
    row-gap: 0
}

.row-gap-1 {
    grid-row-gap: .25rem;
    row-gap: .25rem
}

.row-gap-2 {
    grid-row-gap: .5rem;
    row-gap: .5rem
}

.row-gap-3 {
    grid-row-gap: .75rem;
    row-gap: .75rem
}

.row-gap-4 {
    grid-row-gap: 1rem;
    row-gap: 1rem
}

.row-gap-5 {
    grid-row-gap: 1.25rem;
    row-gap: 1.25rem
}

.row-gap-6 {
    grid-row-gap: 1.5rem;
    row-gap: 1.5rem
}

.row-gap-8 {
    grid-row-gap: 2rem;
    row-gap: 2rem
}

.row-gap-10 {
    grid-row-gap: 2.5rem;
    row-gap: 2.5rem
}

.row-gap-12 {
    grid-row-gap: 3rem;
    row-gap: 3rem
}

.row-gap-16 {
    grid-row-gap: 4rem;
    row-gap: 4rem
}

.row-gap-20 {
    grid-row-gap: 5rem;
    row-gap: 5rem
}

.row-gap-24 {
    grid-row-gap: 6rem;
    row-gap: 6rem
}

.row-gap-32 {
    grid-row-gap: 8rem;
    row-gap: 8rem
}

.grid-flow-row {
    grid-auto-flow: row
}

.grid-flow-col {
    grid-auto-flow: column
}

.grid-flow-row-dense {
    grid-auto-flow: row dense
}

.grid-flow-col-dense {
    grid-auto-flow: column dense
}

.grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr))
}

.grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr))
}

.grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr))
}

.grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
}

.grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
}

.grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
}

.grid-cols-24 {
    grid-template-columns: repeat(24, minmax(0, 1fr));
}

.grid-cols-none {
    grid-template-columns: none;
}

.col-auto {
    grid-column: auto;
}

.col-span-1 {
    grid-column: span 1/span 1;
}

.col-span-2 {
    grid-column: span 2/span 2;
}

.col-span-3 {
    grid-column: span 3/span 3;
}

.col-span-4 {
    grid-column: span 4/span 4;
}

.col-span-5 {
    grid-column: span 5/span 5;
}

.col-span-6 {
    grid-column: span 6/span 6;
}

.col-span-7 {
    grid-column: span 7/span 7;
}

.col-span-8 {
    grid-column: span 8/span 8;
}

.col-span-9 {
    grid-column: span 9/span 9;
}

.col-span-10 {
    grid-column: span 10/span 10;
}

.col-span-11 {
    grid-column: span 11/span 11;
}

.col-span-12 {
    grid-column: span 12/span 12;
}

.col-span-13 {
    grid-column: span 13/span 13;
}

.col-span-14 {
    grid-column: span 14/span 14;
}

.col-span-15 {
    grid-column: span 15/span 15;
}

.col-span-16 {
    grid-column: span 16/span 16;
}


.col-span-17 {
    grid-column: span 17/span 17;
}

.col-span-18 {
    grid-column: span 18/span 18;
}

.col-span-19 {
    grid-column: span 19/span 19;
}

.col-span-20 {
    grid-column: span 20/span 20;
}

.col-span-21 {
    grid-column: span 21/span 21;
}

.col-span-22 {
    grid-column: span 22/span 22;
}

.col-span-23 {
    grid-column: span 23/span 23;
}

.col-span-24 {
    grid-column: span 24/span 24;
}

.col-start-1 {
    grid-column-start: 1
}

.col-start-2 {
    grid-column-start: 2
}

.col-start-3 {
    grid-column-start: 3
}

.col-start-4 {
    grid-column-start: 4
}

.col-start-5 {
    grid-column-start: 5
}

.col-start-6 {
    grid-column-start: 6
}

.col-start-7 {
    grid-column-start: 7
}

.col-start-8 {
    grid-column-start: 8
}

.col-start-9 {
    grid-column-start: 9
}

.col-start-10 {
    grid-column-start: 10
}

.col-start-11 {
    grid-column-start: 11
}

.col-start-12 {
    grid-column-start: 12
}

.col-start-13 {
    grid-column-start: 13
}

.col-start-auto {
    grid-column-start: auto
}

.col-end-1 {
    grid-column-end: 1
}

.col-end-2 {
    grid-column-end: 2
}

.col-end-3 {
    grid-column-end: 3
}

.col-end-4 {
    grid-column-end: 4
}

.col-end-5 {
    grid-column-end: 5
}

.col-end-6 {
    grid-column-end: 6
}

.col-end-7 {
    grid-column-end: 7
}

.col-end-8 {
    grid-column-end: 8
}

.col-end-9 {
    grid-column-end: 9
}

.col-end-10 {
    grid-column-end: 10
}

.col-end-11 {
    grid-column-end: 11
}

.col-end-12 {
    grid-column-end: 12
}

.col-end-13 {
    grid-column-end: 13
}

.col-end-auto {
    grid-column-end: auto
}

.grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr))
}

.grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr))
}

.grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr))
}

.grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr))
}

.grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr))
}

.grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr))
}

.grid-rows-none {
    grid-template-rows: none
}

.row-auto {
    grid-row: auto
}

.row-span-1 {
    grid-row: span 1/span 1
}

.row-span-2 {
    grid-row: span 2/span 2
}

.row-span-3 {
    grid-row: span 3/span 3
}

.row-span-4 {
    grid-row: span 4/span 4
}

.row-span-5 {
    grid-row: span 5/span 5
}

.row-span-6 {
    grid-row: span 6/span 6
}

.row-start-1 {
    grid-row-start: 1
}

.row-start-2 {
    grid-row-start: 2
}

.row-start-3 {
    grid-row-start: 3
}

.row-start-4 {
    grid-row-start: 4
}

.row-start-5 {
    grid-row-start: 5
}

.row-start-6 {
    grid-row-start: 6
}

.row-start-7 {
    grid-row-start: 7
}

.row-start-auto {
    grid-row-start: auto
}

.row-end-1 {
    grid-row-end: 1
}

.row-end-2 {
    grid-row-end: 2
}

.row-end-3 {
    grid-row-end: 3
}

.row-end-4 {
    grid-row-end: 4
}

.row-end-5 {
    grid-row-end: 5
}

.row-end-6 {
    grid-row-end: 6
}

.row-end-7 {
    grid-row-end: 7
}

.row-end-auto {
    grid-row-end: auto
}

.align-center {
    align-items: center;
}

.justify-end {
    justify-self: end;
}

.justify-center {
    justify-self: center;
}

.flex {
    display: flex;
    flex-direction: row;

    &.column {
        flex-direction: column;
    }

    &.align-center {
        align-items: center;
    }

    &.space-between {
        justify-content: space-between;
    }
}