html {
    font-family: 'Roboto', 'Helvetica Neue', 'Arial', sans-serif;
}

body {
    font-size: var(--base-font-size);
    color: var(--font-color-1);
}

html,
body {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
}

.primary-sidenav-container {
    font-size: var(--font-size);
}

i,
mat-icon {
    color: var(--font-color-2);
}


// Headings
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-weight: normal;
}

h1,
.h1 {
    font-size: 24px;
}

h2,
.h2 {
    font-size: 20px;
}

h3,
.h3 {
    font-size: 16px;
}

h4,
.h4 {
    font-size: 15px;
}

h5,
.h5 {
    font-size: 13px;
}

h6,
.h6 {
    font-size: 12px;
}

.text-uppercase {
    text-transform: uppercase;
}

// Truncate
.text-truncate {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
