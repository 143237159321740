:root {
    --primary-color: #1ca1f2;
    --accent-color: #f2a312;
    --warn-color: #ce2a2a;
    --white-color: #ffffff;

    --dark-bg-color: #1C4359;

    --bg-color-1: #ededed;
    --bg-color-2: #6a808c;
    --bg-color-3: #c5c6cb;
    --bg-color-4: #fff;
    --bg-color-5: #e0e0e0;
    --bg-color-6: #e1f5fe;
    --bg-color-7: #7362B2;
    --bg-color-8: #648CA0;
    --bg-color-9: #66B969;
    --bg-color-10: #24ACD8;
    --bg-color-11: #084359;
    --bg-color-12: #1685c1;
    --bg-color-13: rgba(255, 255, 255, 0.57);
    --bg-color-14: #fafafa;
    --bg-color-15: #ff00000f;
    --bg-color-16: rgba(255, 255, 255, 0.59);
    --bg-color-17: #f5f5f5;
    --bg-color-18: green;
    --bg-color-19: #a6a6a6;
    --bg-color-20: #039be5;
    --bg-color-21: #f3f3f4;
    --bg-color-22: rgba(143, 143, 144, 0.5);
    --bg-color-23: rgba(0, 0, 0, 0.04);
    --bg-color-24: rgba(#fff, 0.2);
    --bg-color-25: rgb(24, 28, 48);
    --bg-color-26: rgba(23, 27, 47, 0.9);
    --bg-color-27: #03A9F4;
    --bg-color-28: #fef8f7;

    --bg-status-color-1: #4caf50;
    --bg-status-color-2: #f44336;
    --bg-status-color-3: #555;
    --bg-status-color-4: #0d0;
    --bg-status-color-5: #d00;
    --bg-status-color-6:#f4433638;

    --base-font-size: 16px;
    --font-size: .875em;

    --font-size-1: .875rem;
    --font-size-2: .75rem;

    --font-color-1: rgba(0, 0, 0, 0.87);
    --font-color-2: rgba(0, 0, 0, 0.54);
    --font-color-5: rgba(0, 0, 0, 0.38);

    --font-color-30:rgba(0, 0, 0, 0.12);
    --font-color-4: #fff;
    --font-color-6: #6a808c;
    --font-color-7: #747474;
    --font-color-12: #676767;
    --font-color-14: #ffffffb5;
    --font-color-15: #000;
    --font-color-18: #d0d0d0;
    --font-color-19: grey;
    --font-color-22: #F2A31291;
    --font-color-23: red;
    --font-color-24: #e1e1e1;
    --font-color-25: #848484;
    --font-color-26: #818181;
    --font-color-28: #757575; 

    --shadow-1: 0px 1px 10px 1px rgb(0 0 0 / 14%), 0px 1px 10px 1px rgb(0 0 0 / 14%), 0px 1px 10px 1px rgb(0 0 0 / 14%);
    --shadow-2: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
    --shadow-3: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);

    --border-1: 1px solid var(--font-color-30);
    --border-2: 1px solid var(--accent-color);
    --border-radius-1: .375rem;
}
