/*----------------------------------------------------------------*/
/*  Reset
/*----------------------------------------------------------------*/
* {
    text-rendering: optimizeLegibility;
    -o-text-rendering: optimizeLegibility;
    -ms-text-rendering: optimizeLegibility;
    -moz-text-rendering: optimizeLegibility;
    -webkit-text-rendering: optimizeLegibility;
    -webkit-tap-highlight-color: transparent;
    box-sizing: border-box;

    &:before,
    &:after {
        box-sizing: border-box;
    }

    // Remove focus outline
    &:focus {
        outline: none;
    }
}

html,
body {
    margin: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

*:not(mat-form-field) {
    >input {
        border: none;
        border-radius: 0;
        padding: 0;
        margin: 0;
    }

    >input[type="text"],
    >input[type="tel"],
    >input[type="email"],
    >input[type="search"],
    >input[type="password"],
    >input[type="button"],
    >button,
    >input[type="submit"],
    >input[type="image"],
    >textarea {
        appearance: none;
        border: none;
        border-radius: 0;
        padding: 0;
        margin: 0;
    }

    >input[type="button"],
    >button,
    >input[type="submit"] {
        background: none;
    }
}

button {
    border-radius: 0;
    font-family: inherit;
    font-size: inherit;
    padding: 0;
    margin: 0;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
}

img {
    max-width: 100%;
    height: auto;
    vertical-align: top;
    border: none;
}
