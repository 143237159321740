.large-dialog {
    max-width: 80vw;
    min-width: 80vw;
    max-height: 80vh;
    height: 80vh;

    .mat-mdc-dialog-surface > * {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .mat-mdc-dialog-content {
        height: 100%;
        max-height: 100%;
        flex: 1;
    }

    @media (max-width: 1280px) {
        max-width: 90vw;
        min-width: 90vw;
        max-height: 90vh;
        height: 90vh;
    }

    @media screen and (max-width: 959px) {
        min-width: 100vw;
        width: 100vw;
        min-height: 100vh;
        height: 100vh;

        .mat-mdc-dialog-container .mat-mdc-dialog-surface {
            border-radius: 0;
        }
    }
}



//Material dialog styles

.lg-dialog {
    width: 1200px;
}

.md-dialog {
    width: 800px;
}

.sm-dialog {
    width: 400px;
}

.mat-mdc-dialog-title {
    font-weight: normal;
}

.mat-mdc-dialog-actions {
    button {
        text-transform: uppercase;

        &:not(:last-child) {
            margin-right: 8px;
        }
    }
}

.mdc-dialog-form .mat-mdc-dialog-container {
    .mat-mdc-dialog-title+.mat-mdc-dialog-content {
        padding-top: .25rem;
    }
}

.stepper-lg-dialog {
    width: 90vw;
    height: 86vh;
}

.stepper-lg-dialog,
.stepper-dialog {

    .mat-mdc-dialog-container {
        padding: 0;
    }

    mat-stepper.mat-mdc-dialog-content {
        padding: 0;
    }

    .mat-mdc-dialog-content {
        margin: 0;
        max-height: 100%;
        height: 100%;
        padding: 0;
        display: flex;
        flex-direction: column;
        background-color: var(--bg-color-17);
    }

    .mat-step-body {
        position: relative;
        height: calc(100% - 36px - 1rem);
        overflow-x: hidden;
        overflow-y: auto;
    }

    .mat-horizontal-stepper-header {

        .mat-step-icon {
            width: 2rem;
            height: 2rem;
            font-size: 1.25rem;
        }

        .mat-icon {
            min-width: 1.25rem;
            min-height: 1.25rem;
            height: 1.25rem;
            font-size: 1.25rem;
            width: 1rem;
            color: white;
        }

        &.cdk-focused {
            background-color: transparent !important;
        }
    }

    .mat-horizontal-stepper-wrapper {
        flex: 1;
        height: 100%;
    }

    .mat-horizontal-content-container {
        flex: 1;
        padding-bottom: 1rem;

        .mat-horizontal-stepper-content:not(.mat-horizontal-stepper-content-inactive) {
            height: 100%;
        }
    }

    .mat-step-actions {
        display: flex;
        padding-top: 1rem;

        button {
            width: 120px;
        }

        button+button {
            margin-left: 1rem;
        }
    }
}

.records-selection-dialog-panel {
    width: 65vw;
    height: 80vh;

    .mat-mdc-dialog-content {
        height: 100%;
        max-height: 100%;
        flex: 1;
    }

    .mat-mdc-dialog-surface > * {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
}

.grey-dialog-panel {
    .mat-mdc-dialog-container .mat-mdc-dialog-surface {
        background-color: var(--bg-color-17);
    }
}

@media screen and (max-width: 959px) {

    .stepper-lg-dialog,
    .lt-md-fullscreen-dialog {
        min-width: 100vw;
        width: 100vw;
        min-height: 100vh;
        height: 100vh;

        .mat-mdc-dialog-container {
            border-radius: 0;
        }

        .mat-mdc-dialog-content {
            height: 100%;
            max-height: 100%;
            flex: 1;
        }

        .mat-mdc-dialog-surface > * {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
    }

}